import { Grid, Card, TextField, MenuItem, Button } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import './App.css';
import Header from './Header';

function App() {
  const [documentType, setDocumentType] = useState("");
  const [number, setNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [frontImg, setFrontImg] = useState("");
  const [backImg, setBackImg] = useState("");
  const [remarks, setRemarks] = useState("");
  //const [files, setFiles] = useState<any>(Array);
  const [fronFile, setFrontFile] = useState("");
  const [backFile, setBackFile] = useState("");

  const isAlphaNumeric = (str: any) => /^[a-zA-Z0-9]*$/.test(str);

  const onInputChange = (event: any) => {
    const { value } = event.target;
    if (isAlphaNumeric(value)) {
      setNumber(value);
    }
  }

  function onFrontFileUpload(event: any) {
    setFrontFile(event.target.files[0]);
  }

  function onBackFileUpload(event: any) {
    setBackFile(event.target.files[0]);
  }

  // function onFileUpload(event: any) {
  //   setFiles([...files, { file: event.target.files[0], fileName: event.target.files[0].name }]);
  // }

  const addDocument = () => {
    const formData = new FormData();
    // for(let i= 0;i<files.length;i++) {
    //   formData.append("file", files[i]['file']);
    // }
    formData.append("frontFile", fronFile);
    formData.append("backFile", backFile);
    formData.append("documentType", documentType);
    formData.append("number", number);
    formData.append("expiryDate", expiryDate);
    formData.append("remarks", remarks);

    axios.post('https://doclockerbackend-nvhvk3nodq-uc.a.run.app/addDocument', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        if(res.data['status'] === 409) {
          alert(res.data['message'])
        } else if(res.data['status'] === 200){
          alert(res.data['message'])
        }
        setNumber("");
        setDocumentType("");
        setExpiryDate("");
        setFrontImg("");
        setBackImg("");
        setRemarks("");
      })
      .catch(err => console.log(err))
  }

  return (
    <div>
      <Header />
      <Grid container justifyContent="center">
        <Card style={{ marginTop: "50px", width: "500px", height: "500px" }}>
          <form style={{ textAlign: "center" }}>
            <TextField label="Document Type" select variant='outlined' size='small'
              style={{ marginTop: "20px", width: "300px", textAlign: "left" }}
              value={documentType} onChange={(event) => { setDocumentType(event.target.value) }}>
              <MenuItem value="Passport">Passport</MenuItem>
              <MenuItem value="Driving Liceanse">Driving Liceanse</MenuItem>
              <MenuItem value="Insurance">Insurance</MenuItem>
              <MenuItem value="Aadhar">Aadhar</MenuItem>
            </TextField>
            <br></br>
            <TextField value={number} onChange={onInputChange} label="Number" variant='outlined'
              size='small' style={{ width: "300px", marginTop: "15px" }} helperText="Please enter a unique number" ></TextField>
            <br></br>
            <TextField type={"date"} label="Expiry Date" variant='outlined'
              size='small' style={{ width: "300px", marginTop: "15px" }} InputLabelProps={{ shrink: true }}
              value={expiryDate} onChange={(event) => { setExpiryDate(event.target.value) }} ></TextField>
            <br></br>
            <TextField type={"file"} label="Front Image" variant='outlined' inputProps={{ accept: "image/png, image/jpeg" }}
              size='small' style={{ width: "300px", marginTop: "15px" }} InputLabelProps={{ shrink: true }}
              value={frontImg} onChange={(event) => { setFrontImg(event.target.value); onFrontFileUpload(event); }}></TextField>
            <br></br>
            <TextField type={"file"} label="Back Image" variant='outlined'
              size='small' style={{ width: "300px", marginTop: "15px" }} InputLabelProps={{ shrink: true }}
              value={backImg} onChange={(event) => { setBackImg(event.target.value); onBackFileUpload(event) }} ></TextField>
            <br></br>
            <TextField type={"text"} multiline rows={2} maxRows={4} label="Remarks" variant='outlined'
              size='small' style={{ width: "300px", marginTop: "15px" }}
              value={remarks} onChange={(event) => { setRemarks(event.target.value) }} ></TextField>
            <br></br>
            <Button style={{ width: "300px", color: "white", backgroundColor: "green", marginTop: "30px" }} onClick={addDocument} >Submit</Button>
          </form>
        </Card>
      </Grid>
    </div>
  );
}

export default App;
