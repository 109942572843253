import { Button, Card, Grid, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Header from './Header';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import axios from 'axios';

function View() {
    const headerStyle = {
        color: "white",
        fontWeight: "bold",
        fontSize: "16px"
    }
    const [open, setOpen] = useState(false);
    const [documentInfo, setDocumentInfo] = useState<any>(Array);
    const [documentType, setDocumentType] = useState("");
    const [number, setNumber] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [frontImg, setFrontImg] = useState("");
    const [backImg, setBackImg] = useState("");
    const [fronFile, setFrontFile] = useState("");
    const [backFile, setBackFile] = useState("");
    const [id, setId] = useState("");

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        fetchDocumentInfo();
    }, []);

    function onFrontFileUpload(event: any) {
        setFrontFile(event.target.files[0]);
    }

    function onBackFileUpload(event: any) {
        setBackFile(event.target.files[0]);
    }

    const fetchDocumentInfo = async () => {
        const result = await axios.get('https://doclockerbackend-nvhvk3nodq-uc.a.run.app/getDocuments');
        setDocumentInfo(result.data);
        console.log(result.data);
    }

    const editDocumentInfo = async (documentId: any) => {
        setId(documentId);
        const result = await axios.get(`https://doclockerbackend-nvhvk3nodq-uc.a.run.app/getDocument/${documentId}`);
        console.log(result.data);
        setDocumentType(result.data[0]['documenttype']);
        setNumber(result.data[0]['number']);
        setExpiryDate(result.data[0]['expirydate'].split('T')[0]);
    }

    const updateDocument = async () => {
        const formData = new FormData();
        formData.append("frontFile", fronFile);
        formData.append("backFile", backFile);
        formData.append("expiryDate", expiryDate);
        const result = await axios.put(`https://doclockerbackend-nvhvk3nodq-uc.a.run.app/updateDocument/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        console.log(result);
        if (result['status'] === 200) {
            alert("Document Updated.");
        }
        fetchDocumentInfo();
    }

    const deleteDocument = async (documentId: any) => {
        const result = await axios.delete(`https://doclockerbackend-nvhvk3nodq-uc.a.run.app/deleteDocument/${documentId}`);
        console.log(result);
        if (result['status'] === 200) {
            alert("Document Deleted.");
        }
        fetchDocumentInfo();
    }

    async function sendEmail(documentId: any){
        const result = await axios.get(`https://doclockerbackend-nvhvk3nodq-uc.a.run.app/getDocument/${documentId}`);
        console.log(result.data[0]);
        const data = result.data[0];
        window.location.assign(`mailto:?subject=Document Information&body=Document Type : ${data.documenttype} %0D%0A Number : ${data.number} %0D%0A Expiry Date : ${data.expirydate} %0D%0A Front Image Url : ${data.frontimage.imgUrl} %0D%0A Back Image Url : ${data.backimage.imgUrl} %0D%0A Remarks : ${data.remarks}`);
    }

    return (
        <div>
            <Header />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650, marginTop: "30px" }} >
                    <TableHead style={{ backgroundColor: "seagreen" }}>
                        <TableRow>
                            <TableCell style={headerStyle}>Document Type</TableCell>
                            <TableCell align="right" style={headerStyle}>Number</TableCell>
                            <TableCell align="right" style={headerStyle}>Expiry Date</TableCell>
                            <TableCell align="right" style={headerStyle}>Front Image</TableCell>
                            <TableCell align="right" style={headerStyle}>Back Image</TableCell>
                            <TableCell align="right" style={headerStyle}>Modify</TableCell>
                            <TableCell align="right" style={headerStyle}>Send Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentInfo.map((element: any) => (
                            <TableRow key={element.id}>
                                <TableCell>{element.documenttype}</TableCell>
                                <TableCell align="right">{element.number}</TableCell>
                                <TableCell align="right">{element.expirydate.split('T')[0]}</TableCell>
                                <TableCell align="right"><img width="50" height="50" src={element.frontimage.imgUrl} alt=""></img></TableCell>
                                <TableCell align="right"><img width="50" height="50" src={element.backimage.imgUrl} alt=""></img></TableCell>
                                <TableCell align="right"><IconButton onClick={() => { editDocumentInfo(element.id); handleOpen() }}><ModeEditOutlineOutlinedIcon style={{ color: "blue" }} /></IconButton><IconButton sx={{ "&:hover": { color: "red" } }} onClick={() => { deleteDocument(element.id) }}><DeleteForeverOutlinedIcon /></IconButton></TableCell>
                                <TableCell align="right"><IconButton sx={{ "&:hover": { color: "red" } }} onClick={() => { sendEmail(element.id) }}><EmailOutlinedIcon /></IconButton></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal open={open} onClose={handleClose}>
                <Grid container justifyContent="center">
                    <Card style={{ marginTop: "50px", width: "450px", height: "500px" }}>
                        <div style={{ display: "flex" }}>
                            <IconButton style={{ marginTop: "20px", marginLeft: "auto", marginRight: "20px" }} onClick={handleClose}><CloseOutlinedIcon /></IconButton>
                        </div>
                        <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>Edit Document</p>
                        <form style={{ textAlign: "center" }}>
                            <TextField label="Document Type" variant='outlined' size='small' value={documentType}
                                style={{ marginTop: "20px", width: "300px", textAlign: "left" }} disabled>
                            </TextField>
                            <br></br>
                            <TextField label="Number" variant='outlined' disabled value={number}
                                size='small' style={{ width: "300px", marginTop: "15px" }} ></TextField>
                            <br></br>
                            <TextField type="date" label="Expiry Date" variant='outlined' value={expiryDate} onChange={(event) => { setExpiryDate(event.target.value) }}
                                size='small' style={{ width: "300px", marginTop: "15px" }} InputLabelProps={{ shrink: true }} ></TextField>
                            <br></br>
                            <TextField type="file" label="Front Image" variant='outlined' value={frontImg}
                                size='small' style={{ width: "300px", marginTop: "15px" }} InputLabelProps={{ shrink: true }}
                                onChange={(event) => { setFrontImg(event.target.value); onFrontFileUpload(event); }} ></TextField>
                            <br></br>
                            <TextField type="file" label="Back Image" variant='outlined' value={backImg}
                                size='small' style={{ width: "300px", marginTop: "15px" }} InputLabelProps={{ shrink: true }}
                                onChange={(event) => { setBackImg(event.target.value); onBackFileUpload(event) }}></TextField>
                            <br></br>
                            <Button style={{ width: "300px", color: "white", backgroundColor: "green", marginTop: "30px" }} onClick={updateDocument} >Update</Button>
                        </form>
                    </Card>
                </Grid>
            </Modal>
        </div>
    )
}

export default View;