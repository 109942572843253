import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import View from './View';

function Routing() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App />} ></Route>
                <Route path='/view' element={<View />} ></Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Routing;