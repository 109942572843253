import { AppBar, Button, Toolbar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();

    return (
        <div>
            <AppBar position='static' style={{backgroundColor: "black"}}>
                <Toolbar>
                    <Button id="add" className='add-button' onClick={() => {navigate('/')}}
                    style={{textTransform:"none", fontWeight:"bold", color: "white", fontSize:"18px"}}>Add</Button>
                    <Button id="view" className='view-button' onClick={() => {navigate('/view')}}
                    style={{textTransform:"none", fontWeight:"bold", color: "white", fontSize:"18px"}}>View</Button>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Header;